import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Breadcrumb from '../../components/shop/breadcrumb';
import Framework from '../../components/framework';
import SEO from '../../components/framework/seo';
import Share from '../../components/tools/share';

const ProductTemplate = ({ data, location }) => {
	const product = data.productsCsv;

	const breadcrumb = [
		{ name: product.Category, url: product.fields.slug_category },
		{ name: product.Name },
	];
	const header = {
		class: 'mt-5',
		title: product.Name,
	};
	const productUrl = product[product.Primary_site];

	return (
		<Framework header={header} location={location}>
			<SEO
				description={product.Description}
				id={product.SKU}
				image={product.Image1}
				location={location}
				tags={`${product.Tags}, ${product.Category.toLowerCase()}`}
				title={`${product.Name} | ${product.Category}`}
				type="product"
			/>

			<div className="container">
				<Breadcrumb items={breadcrumb} />
				<div className="row">
					<div className="col-lg">
						<a
							href={productUrl}
							rel="noopener noreferrer"
							target="_blank"
							title={product.Name}
						>
							<img
								alt={product.Name}
								className="img-fluid"
								src={product.Image1}
							/>
						</a>
					</div>
					<div className="col-lg">
						<p className="mb-5 mt-1">{product.Description}</p>
						<a
							className="btn btn-block btn-lg btn-success"
							href={productUrl}
							rel="noopener noreferrer"
							target="_blank"
							title={product.Name}
						>
							Buy
						</a>

						<div className="mt-5">
							<Share
								image={product.Image1}
								name={product.Name}
								tags={['denizkoart', product.Category.toLowerCase()]}
								url={product.fields.slug_url}
							/>
						</div>
					</div>
				</div>
			</div>
		</Framework>
	);
};

ProductTemplate.propTypes = {
	data: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

export default ProductTemplate;

export const pageQuery = graphql`
	query ProductBySlug($slug: String!) {
		productsCsv(fields: { slug: { eq: $slug } }) {
			...ProductFragment
		}
	}
`;
